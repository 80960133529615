@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.scroll {
  overflow: auto;
  float: left;
}

.scroll1::-webkit-scrollbar {
  width: 5px;
}

.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}

.scroll1::-webkit-scrollbar-thumb {
  background: #666;
}

.section {
  font-size: 1.4em;
  letter-spacing: 0.05em;
  line-height: 1.5em;
}

.section h2,
.section h3 {
  text-align: center;
  margin: 0;
  font-weight: normal;
}

.section__header {
  position: sticky;
  top: 0;
  padding: 1em;
}

.section__content {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.section__content > div {
  max-width: 992px;
}
